import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Data } from '@angular/router';
import { Subject } from 'rxjs';
import { URL } from 'url';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private http: HttpClient) { }

  sendPdf(pdf:ArrayBuffer, image:any = null, sig: any = null) {
    let obj = {
      to : [
        {email: "hesse@bhesselaw.com"}
      ],
      from : {email: "bot@sharefold.com", name: "HesseFormBot"},
      subject : "B-Hesse Pdf Form",
      text : "This form was automatically sent by the web form Application",
      attachments: [
        {
          content: this.bufferToBase64(pdf),
          type: "application/pdf",
          disposition: 'attachment',
          filename: "form.pdf",
          contentId: 'myfile'
        }
      ]
    }
    if(image != null){
      obj.attachments.push({
        content: image,
        type: "image/jpeg",
        disposition: 'attachment',
        filename: "ProfileImage.png",
        contentId: 'myimage'
      })
    }
    else if(sig != null){
      obj.attachments.push({
        content: sig,
        type: 'application/pdf',
        disposition: 'attachment',
        filename: "sig.pdf",
        contentId: 'mysignature'
      })
    }
    console.log(obj);
    return this.http.post<any>('https://mailer.sharefold.com/v1/mail/send', obj)
  }

  bufferToBase64(buf:any) {
    let binstr = Array.prototype.map.call(buf, (ch) => {
      return String.fromCharCode(ch);
    }).join('');
    return btoa(binstr);
  }
}
